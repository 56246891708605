import { buildProductUrl } from 'utils/buildProductUrl';
import { Currency, ProductSearchHit } from '__generated__/graphql';
import { getMappedCountryCode } from 'utils/locale';
export function itemListSchema(
  products: ProductSearchHit[],
  currency: Currency,
  host: string,
  countryCode: string,
  language: string
) {
  const getProductUrl = (product: ProductSearchHit) => {
    const id = product.masterProduct?.id;
    const name =
      product.variantProduct?.name || product.masterProduct?.name || '';
    const color = product.variantProduct?.colorValue || '';
    const hostUrl = host.startsWith('localhost')
      ? `http://${host}/`
      : `https://${host}/`;
    const mappedCountryCode =
      getMappedCountryCode(countryCode) || countryCode.toLowerCase();
    const locale = `${mappedCountryCode}/${language}`;

    return `${hostUrl}${locale}${buildProductUrl(id ?? '', name, {
      color,
    })}`;
  };
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: products
      .filter(product => product.masterProduct)
      .map((product, index) => {
        const masterProduct = product.masterProduct!;
        return {
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'Product',
            image: product.variantProduct?.preview || masterProduct.image?.href,
            name: product.variantProduct?.name || masterProduct.name || '',
            url: getProductUrl(product),
            brand: {
              '@type': 'Brand',
              name: 'PUMA',
            },
            offers: {
              '@type': 'Offer',
              price:
                product.variantProduct?.salePrice ||
                product.variantProduct?.price,
              priceCurrency: currency.code,
              availability: 'http://schema.org/InStock',
            },
          },
        };
      }),
  });
}
