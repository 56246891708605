import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Hreflangs, availablePages } from 'ui/components/Hreflangs';
import { Page } from 'ui/components/Page';
import { ProductList } from 'ui/components/ProductList';
import { Breadcrumb } from 'ui/components/Breadcrumb';
import { constructCategoryPath } from 'utils/constructCategoryPath';
import { Prose } from 'utils/serializeBlockContent';
import {
  PositionedTile,
  ProductSearchHit,
  useMeQuery,
  useRecommendationsWithDetailsQuery,
} from '__generated__/graphql';
import { InlineReadMore } from 'ui/components/InlineReadMore';
import { ProductListItem } from 'ui/components/ProductListItem';
import { ScrollToTopButton } from 'ui/elements/ScrollToTopButton';
import { PageHead } from 'ui/components/PageHead';
import { useCategoryPLPQuery } from 'gql/queries/plp';
import { MAX_PRODUCT_PAGE_SIZE } from 'utils/products';
import { useTranslate } from 'hooks/useTranslations';
import { ProductListFilterProvider } from 'ui/components/ProductListFilterProvider';
import { SanityContentSlot } from 'ui/components/SanityContentSlot';
import { useEmarsysWebExtend } from 'hooks/useEmarsysWebExtend';
import { useGroqQuery } from 'hooks/useGroqQuery';
import {
  DefaultProductListingPageQuery,
  TopContentSlotPLPQuery,
  BottomContentSlotPLPQuery,
} from 'groq/pages/ProductListingPage';
import { isInitialClientRender } from 'utils/isInitialClientRender';
import { useDynamicYieldContext } from 'hooks/useDynamicYieldContext';
import { dyContext } from 'utils/dynamicYield';
import {
  AnalyticsEvents,
  event,
  handleIntersectionPLP,
  Promotion,
  searchAnalyticsEvent,
} from 'utils/analytics';
import { useGetRecommenderLocation } from 'hooks/useGetRecommenderLocation';
import { filterActivated } from 'utils/filterActivated';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useScreenSize } from 'hooks/useScreenSize';
import { useAuth } from 'hooks/useAuth';
import { useFeature } from 'hooks/useFeature';
import { getPreviewInput } from 'utils/getPreviewInput';
import { getUrlByCategory } from 'utils/getUrlByCategory';
import { useIsServer } from 'hooks/useIsServer';

import { PaginatedProductListPage } from '../components/PaginatedProductListPage';

export const ProductListingPage = () => {
  const auth = useAuth();
  const userQueryDY = useFeature('USER_QUERY_DYNAMIC_YIELD');
  const router = useRouter();
  const t = useTranslate();
  const {
    staticFeatures: { isPNARegion },
    host,
    country,
  } = useSiteConfig();
  const isServer = useIsServer();
  const { trackCategory } = useEmarsysWebExtend();
  const desiredCategory = router.query.category as string[];
  const categoryUrl = getUrlByCategory(desiredCategory);
  const [query, refetch] = useCategoryPLPQuery({
    router,
    offset: 0,
    includeMetadata: true,
    url: categoryUrl,
    src: router.query.src as string,
    preview: getPreviewInput(router.query),
  });
  const [meResult] = useMeQuery({
    pause: auth.guest || !userQueryDY,
  });
  const categoryId = query.data?.categoryByUrl?.id;
  const categoryGeneralId = query.data?.categoryByUrl?.id;

  const parsedCategory =
    categoryId && categoryId.split('-').length > 2
      ? [
          categoryId.split('-')[0],
          categoryId.split('-')[1],
          categoryId.split('-').slice(2).join('-'),
        ]
      : categoryId?.split('-');

  const me = meResult.data?.me;

  useDynamicYieldContext({
    type: dyContext.CATEGORY,
    data: parsedCategory,
    user: me,
    pause: !parsedCategory,
  });

  useEffect(() => {
    // Refetch this query on the client within the context of a customer
    // if this is the first render after SSR
    if (isInitialClientRender(router)) {
      refetch({ requestPolicy: 'cache-and-network' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [defaultPlpContent] = useGroqQuery({
    operationName: 'DefaultProductListingPage',
    query: DefaultProductListingPageQuery,
    variables: {
      url: categoryUrl,
    },
  });

  const [topPlpContent] = useGroqQuery({
    operationName: 'TopContentSlotPLP',
    query: TopContentSlotPLPQuery,
    variables: {
      url: categoryUrl,
    },
  });

  const [bottomPlpContent] = useGroqQuery({
    operationName: 'BottomContentSlotPLP',
    query: BottomContentSlotPLPQuery,
    variables: {
      url: categoryUrl,
    },
  });

  const pageContent = {
    ...defaultPlpContent?.data?.content,
    ...topPlpContent?.data?.content,
    ...bottomPlpContent?.data?.content,
  };

  const {
    recommenderId,
    recommenderEnabled,
    topContent,
    bottomContent,
    bottomSeoContent,
    tiles,
    seo,
  } = pageContent || {};

  const currentCategory = query.data?.categoryByUrl;
  const categoryPath = useMemo(
    () => constructCategoryPath(currentCategory),
    [currentCategory]
  );

  const totalCount = currentCategory?.products.totalCount;
  const isFromRedirect = useCallback(() => {
    return window.sessionStorage.getItem('user_action') === 'redirect';
  }, []);

  useEffect(() => {
    if (!query.fetching && isFromRedirect())
      searchAnalyticsEvent(String(totalCount));
  }, [totalCount, query.fetching, isFromRedirect]);

  const path = [{ label: 'home', url: '' }, ...categoryPath];
  const pageParam = router.query.page;
  const serverPage = Number(pageParam) > 1 ? Number(pageParam) : 1;

  useEffect(() => {
    if (query.fetching || !query.data) return;
    trackCategory(categoryPath.map(x => x.label));
  }, [categoryPath, trackCategory, query.data, query.fetching]);

  const [isServerState, setIsServerState] = useState<boolean>(true);

  useEffect(() => {
    setIsServerState(false);
  }, []);

  useEffect(() => {
    if (pageParam && !Boolean(router?.query?.offset)) {
      const offset = (serverPage - 1) * MAX_PRODUCT_PAGE_SIZE;

      router.replace(
        {
          query: {
            ...router.query,
            offset: offset,
          },
        },
        '',
        { scroll: false }
      );
    }
  }, [pageParam, router, serverPage]);

  const queryOffset = Number(router.query.offset) || 0;
  const totalProductsCount = currentCategory?.products.totalCount ?? 0;
  const offset =
    totalProductsCount && queryOffset >= totalProductsCount
      ? totalProductsCount - MAX_PRODUCT_PAGE_SIZE
      : queryOffset;

  const numberOfPages =
    pageParam && !offset
      ? serverPage * MAX_PRODUCT_PAGE_SIZE >= totalProductsCount
        ? Math.ceil(totalProductsCount / MAX_PRODUCT_PAGE_SIZE)
        : serverPage
      : Math.ceil(offset / MAX_PRODUCT_PAGE_SIZE) + 1;

  const pages = new Array(numberOfPages).fill(null);

  const prevPage = serverPage > 2 ? serverPage - 1 : 1;
  const nextPage = serverPage + 1;
  const isFinalPageOfResults =
    serverPage * MAX_PRODUCT_PAGE_SIZE >= totalProductsCount;

  const shouldRecommendProducts = recommenderEnabled && !!recommenderId;

  const [recommenderResult] = useRecommendationsWithDetailsQuery({
    variables: {
      pageType: recommenderId!,
      ids: currentCategory?.id ? [currentCategory.id] : [],
    },
    pause: isServer || !shouldRecommendProducts || !currentCategory?.id,
  });

  const recommenderLocation = useGetRecommenderLocation();
  const recommenderName =
    recommenderResult.data?.recommendationsWithDetails.recommenderName;

  useEffect(() => {
    if (recommenderName) {
      event(AnalyticsEvents.PRODUCT_RECOMMENDER_VIEWED, {
        ecommerce: {
          productRecommenderLocation: recommenderLocation(),
          recommenderName,
        },
      });
    }
  }, [recommenderLocation, recommenderName]);

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem('last-clicked-promotion');
    };
  }, []);

  const hasActiveFilter = !!query.operation?.variables?.filters.length;
  const hasExplicitSort = !!query.operation?.variables?.sort;
  const recommendations =
    recommenderResult.data?.recommendationsWithDetails?.recommendations ?? [];

  const filteredRecommendations = recommendations.filter(
    x => x?.__typename === 'ProductSearchHit'
  ) as ProductSearchHit[];

  const shouldDisplayRecommenderRow =
    shouldRecommendProducts &&
    recommendations.length &&
    !hasActiveFilter &&
    !hasExplicitSort;

  const recommended = shouldDisplayRecommenderRow
    ? filteredRecommendations?.slice(0, 4)
    : [];

  const totalCountToShow = totalProductsCount + recommended.length;
  const showLoadingIndicator =
    '/' + (desiredCategory as string[]).join('/') !== currentCategory?.url ||
    (offset !== 0 && query.fetching && !query.data);
  const intersectedProductsRef = useRef<ProductSearchHit[]>([]);
  const { pageviewEventHasFired } = usePageEventsContext();
  const { currency } = useSiteConfig();
  const intersectAllProductsRef = useRef<ProductSearchHit[]>([]);
  const screenSize = useScreenSize();
  const [listProducts, setListProducts] = useState<ProductSearchHit[]>([]);
  const [isCompactMode, setIsCompactMode] = useState<boolean>(false);
  const handleCompactMode = (entry: boolean) => {
    setIsCompactMode(entry);
  };
  const handleIntersection = useCallback(
    (productId: string) => {
      const storedPromotion = window.sessionStorage.getItem(
        'last-clicked-promotion'
      );
      const promotion: Promotion =
        storedPromotion !== null ? JSON.parse(storedPromotion) : undefined;
      handleIntersectionPLP({
        productId,
        intersectedProductsRef,
        intersectAllProductsRef,
        listProducts,
        screenSize,
        currency,
        offset,
        pageviewEventHasFired,
        currentCategory,
        promotion,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listProducts, screenSize, pageviewEventHasFired]
  );

  const [pathname] = router.asPath.split('?');
  const protocol = host.startsWith('localhost') ? 'http://' : 'https://';

  const indexValue = pageParam && country === 'GB' ? true : seo?.noIndex;
  const flagPNARecommended = isPNARegion && filterActivated(router.query);
  return (
    <>
      <PageHead
        title={
          seo?.pageTitle
            ? seo.pageTitle
            : currentCategory?.seo?.pageTitle ??
              t('pageTitle', {
                title: '',
              })
        }
        description={
          seo?.pageDescription
            ? seo.pageDescription
            : currentCategory?.seo?.pageDescription ?? ''
        }
        openGraphDescription={seo?.openGraphDescription ?? ''}
        openGraphImageUrl={seo?.openGraphImageUrl ?? ''}
        openGraphTitle={seo?.openGraphTitle ?? ''}
        openGraphType={seo?.openGraphType ?? ''}
        openGraphVideoUrl={seo?.openGraphVideoUrl ?? ''}
        twitterDescription={seo?.twitterDescription ?? ''}
        twitterImageUrl={seo?.twitterImageUrl ?? ''}
        twitterTitle={seo?.twitterTitle ?? ''}
        noIndex={indexValue}
        noIndexFollow={indexValue}
        noHreflangs={availablePages.includes(categoryGeneralId || '')}
      />
      <Hreflangs id={categoryGeneralId} />
      <Page
        query={{
          ...query,
          fetching: showLoadingIndicator,
        }}
        emarsysData={{
          skipTracking: false,
        }}
        bloomreachData={{
          cat: currentCategory?.name,
          cat_id: currentCategory?.id,
        }}
      >
        <ProductListFilterProvider
          isFetching={query.fetching}
          noResults={totalProductsCount === 0}
          filteringOptions={currentCategory?.products?.filteringOptions || []}
          sortingOptions={currentCategory?.products.sortingOptions || []}
          defaultSortOption={currentCategory?.products.selectedSort || ''}
          totalCount={totalCountToShow}
        >
          <Breadcrumb path={path} />
          <SanityContentSlot
            items={topContent}
            slotId="topContent"
            documentId={pageContent?._id}
          />
          <h1
            className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl uppercase font-bold"
            data-test-id="product-results"
          >
            <span>{currentCategory?.title || currentCategory?.name}</span>
          </h1>
          <ProductList
            isFetching={query.fetching}
            noResults={totalProductsCount === 0}
            filteringOptions={currentCategory?.products?.filteringOptions || []}
            sortingOptions={currentCategory?.products.sortingOptions || []}
            defaultSortOption={currentCategory?.products.selectedSort || ''}
            totalCount={totalCountToShow}
            handleCompactMode={handleCompactMode}
          >
            {!flagPNARecommended &&
              recommended?.map((p, i) => (
                <ProductListItem
                  key={p.id}
                  position={offset + i + 1}
                  categoryId={currentCategory?.id}
                  fromRecommender={
                    recommenderResult.data?.recommendationsWithDetails
                  }
                  itemListId={currentCategory?.id}
                  itemListName={currentCategory?.name}
                  {...p}
                />
              ))}
            {pages.map((_, i) => {
              const isFirstPage = i === 0;
              const isLastPage = i === pages.length - 1;
              return !pageParam || (pageParam && i >= serverPage - 1) ? (
                <PaginatedProductListPage
                  isFirst={(isFirstPage && !pageParam) || serverPage - 1 === i}
                  isLast={isLastPage}
                  totalProducts={totalProductsCount}
                  key={i}
                  offset={i * MAX_PRODUCT_PAGE_SIZE}
                  tiles={tiles as PositionedTile[] | null}
                  query={null}
                  productsRef={intersectedProductsRef}
                  allProductsRef={intersectAllProductsRef}
                  handleIntersection={handleIntersection}
                  setListProducts={setListProducts}
                  isCompactMode={isCompactMode}
                />
              ) : null;
            })}
          </ProductList>
          <div className="mb-6 md:mb-8">
            {currentCategory?.description && (
              <InlineReadMore
                className="text-puma-black text-base"
                innerHtmlString={currentCategory?.description}
              />
            )}
            <div
              className={`text-puma-black text-base ${
                isServerState ? '' : 'hidden'
              }`}
            >
              {serverPage === 2 && (
                <a href={`${protocol}${host}${pathname}`} className="mr-1">
                  Previous
                </a>
              )}
              {serverPage > 2 && (
                <a
                  href={`${protocol}${host}${pathname}?page=${prevPage}`}
                  className="mr-1"
                >
                  Previous
                </a>
              )}
              {!isFinalPageOfResults && (
                <a href={`${protocol}${host}${pathname}?page=${nextPage}`}>
                  Next
                </a>
              )}
            </div>
          </div>
          <SanityContentSlot
            items={bottomContent}
            slotId="bottomContent"
            documentId={pageContent?._id}
          />
          {bottomSeoContent && <Prose content={bottomSeoContent} />}
        </ProductListFilterProvider>
        <ScrollToTopButton />
      </Page>
    </>
  );
};
