type ProductTile = any;
type PromoTile = { position: number };
type Tile = any;

export const splicePromoTiles = (
  products: ProductTile[],
  promoTiles: PromoTile[],
  offset = 0
): Tile[] => {
  if (!promoTiles.length) return products;

  const items: Tile[] = [...products];
  const validPromoTiles: PromoTile[] = [...promoTiles];

  // if not on the first page, we need to eliminate the promo
  // tiles which would have been placed on previous pages
  let firstValidPosition = offset + 1;
  if (offset > 0) {
    const placeholderItems = new Array(offset).fill('-');
    const previousItems = splicePromoTiles(placeholderItems, promoTiles, 0);
    firstValidPosition = previousItems.length + 1;
  }

  // we then need to determine the expected number of items would result for the
  // current page
  let expectedResultLength = items.length;
  for (const tile of validPromoTiles) {
    if (
      tile.position >= firstValidPosition &&
      tile.position <= firstValidPosition + expectedResultLength
    )
      expectedResultLength += 1;
  }

  // we then need to only consider the promo which would be valid for the
  // current range
  const applicablePromoTiles = validPromoTiles.filter(
    x =>
      x.position >= firstValidPosition &&
      x.position < firstValidPosition + expectedResultLength
  );

  for (const tile of applicablePromoTiles) {
    const idx = tile.position - firstValidPosition;
    items.splice(idx, 0, tile);
  }

  return items;
};
