import { useRouter } from 'next/router';
import React, { MutableRefObject, useEffect, useState } from 'react';

import { itemListSchema } from 'utils/itemListSchema';
import { InjectedScript } from 'ui/elements/InjectedScript';
import { CategoryPLPQueryState, useCategoryPLPQuery } from 'gql/queries/plp';
import { ProductListItem } from 'ui/components/ProductListItem';
import { PromoTile } from 'ui/content/PromoTile';
import { splicePromoTiles } from 'utils/productLists';
import { PositionedTile, ProductSearchHit } from '__generated__/graphql';
import { TileWithPosition } from '__generated__/graphql-sanity';
import { useProductListAnalytics } from 'hooks/useProductListAnalytics';
import { getPreviewInput } from 'utils/getPreviewInput';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getUrlByCategory } from 'utils/getUrlByCategory';

import { LoadMore } from './LoadMore';
type Tile = PositionedTile | ProductSearchHit;

type PaginatedProductListPageProps = {
  offset: number;
  tiles: PositionedTile[] | null;
  isFirst: boolean;
  isLast: boolean;
  totalProducts: number;
  query: CategoryPLPQueryState | null;
  productsRef: MutableRefObject<ProductSearchHit[]>;
  allProductsRef: MutableRefObject<ProductSearchHit[]>;
  handleIntersection: (productId: string) => void;
  setListProducts: (entry: ProductSearchHit[]) => void;
  isCompactMode?: boolean;
};

export const PaginatedProductListPage = ({
  offset,
  tiles,
  isLast,
  totalProducts,
  query,
  productsRef,
  allProductsRef,
  handleIntersection,
  setListProducts,
  isFirst,
  isCompactMode,
}: PaginatedProductListPageProps) => {
  const router = useRouter();
  const category = router.query.category as string[];
  const categoryUrl = getUrlByCategory(category);
  const { currency, host, country: countryCode, language } = useSiteConfig();

  const [minimalPaginatedQuery] = useCategoryPLPQuery({
    pause: query !== null,
    router,
    offset,
    includeMetadata: offset === 0,
    url: categoryUrl,
    preview: getPreviewInput(router.query),
  });
  const currentQuery = query ?? minimalPaginatedQuery;

  const products = React.useMemo(
    () =>
      currentQuery?.data?.categoryByUrl?.products.nodes.filter(
        ({ masterProduct }) => masterProduct !== null
      ) || [],
    [currentQuery?.data?.categoryByUrl?.products.nodes]
  );
  setListProducts(products);
  const currentCategory = currentQuery.data?.categoryByUrl;

  useProductListAnalytics({
    offset: offset,
    filters: currentQuery?.operation?.variables?.filters,
    sort: currentQuery?.operation?.variables?.sort,
    products: currentQuery?.data?.categoryByUrl?.products.nodes,
    list: currentQuery?.data?.categoryByUrl?.url,
    categoryId: currentQuery?.data?.categoryByUrl?.id,
    itemListId: currentCategory?.id,
    itemListName: currentCategory?.name,
  });

  const listTiles: Tile[] | TileWithPosition[] = React.useMemo(
    () => splicePromoTiles(products, tiles || [], offset),
    [products, tiles, offset]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '1px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const productId = entry.target.getAttribute('data-product-id');
          const dataTestId = entry.target.getAttribute('data-test-id');
          handleIntersection(productId || dataTestId!);
        }
      });
    }, options);

    document
      .querySelectorAll('[data-test-id="product-list-item"]')
      .forEach(item => {
        observer.observe(item);
      });
    const styliticsPromoTile = document.querySelector(
      '[data-test-id="positioned-promo-tile"]'
    );
    if (styliticsPromoTile) observer.observe(styliticsPromoTile);

    return () => {
      observer.disconnect();
    };
  }, [handleIntersection, productsRef, allProductsRef]);

  const [dimensions, setDimensions] = useState<{
    width: string;
    height: string;
  } | null>(null);

  const handleDimensionsChange = (width: string, height: string) => {
    setDimensions({ width, height });
  };
  return (
    <>
      {!currentQuery.fetching && (
        <>
          {isFirst && products?.length && (
            <InjectedScript
              type="application/ld+json"
              script={itemListSchema(
                products,
                currency,
                host,
                countryCode,
                language
              )}
            />
          )}
          {listTiles.map((item, idx) => {
            const key = `${item.id}_${idx}`;
            if (item.__typename === 'ProductSearchHit') {
              return (
                <ProductListItem
                  key={key}
                  {...item}
                  position={offset + idx + 1}
                  categoryId={currentQuery.data?.categoryByUrl?.id}
                  itemListId={currentCategory?.id}
                  itemListName={currentCategory?.name}
                  onDimensionsChange={handleDimensionsChange}
                  isCompactMode={isCompactMode}
                />
              );
            } else if (item._type === 'tileWithPosition') {
              return (
                <li
                  key={key}
                  className="relative pt-72 xs:pt-96"
                  data-test-id="positioned-promo-tile"
                  style={{
                    width: dimensions?.width,
                    height: dimensions?.height,
                  }}
                >
                  <div className="absolute inset-0">
                    <PromoTile {...item.tile} />
                  </div>
                </li>
              );
            }
            return null;
          })}
        </>
      )}
      <LoadMore
        fetching={currentQuery.fetching}
        isLastPageCurrentlyDisplayed={isLast}
        totalCount={totalProducts}
        itemListId={currentQuery?.data?.categoryByUrl?.id}
        itemListName={currentQuery?.data?.categoryByUrl?.name}
      />
    </>
  );
};
